<template>
  <div class="study">
    <div class="stuHeader">
      <div>
        {{paperName}}
      </div>
      <div>
        {{curPage}}/{{total}}
      </div>
    </div>
    <a-spin class="stuContent" :spinning="loading">
      <div class="subList wrap">
        <div class="subItem" v-if="list.length>0">
          <div class="answerInput">
            <div class="answerContent gmb-10">
              <div class="subInput">
                <a-input v-if="!list[0].check" v-model="list[0].answer" style="width:300px;border:none;" @focus="showErr=false" />
                <div v-else style="width:300px;text-align:center;">{{list[0].answer}}</div>
              </div>
              <a-icon type="sound" style="color:#999;font-size:25px;cursor:pointer" theme="filled" @click="play" />
              <audio :src="list[0].a_voice" id="music"></audio>
            </div>
            <div v-if="showErr" style="color:red" class="gmb-10">请输入听写单词</div>
            <a-button v-if="!list[0].check" type="primary" style="width:220px;" @click="confirmOk">确认</a-button>
          </div>
          <template v-if="list[0].check">
            <div class="check correct" v-if="list[0].check==1">
              <a-icon type="check" />
              <span>回答正确</span>
            </div>
            <div class="check wrong" v-if="list[0].check==2">
              <a-icon type="close" />
              <span>回答错误</span>
            </div>
            <div class="wordMean box">
              <div class="meanTitle gmb-10 gtc">{{list[0].name}}</div>
              <div class="sortBox gmb-10">
                <div>
                  <span>英</span>
                  <span>[{{list[0].e_pronu}}]</span>
                </div>
                <div class="sortType">
                  <div class="typeItem">{{list[0].word_morphology_name.join('/')}}</div>
                </div>
              </div>
              <div class="sortBox gmb-10">
                <div>
                  <span>美</span>
                  <span>[{{list[0].a_pronu}}]</span>
                </div>
                <div class="sortType">
                  <div class="typeItem">{{list[0].word_classification_name.join('/')}}</div>
                </div>
              </div>
              <a-row class="meanItem gmb-10" :gutter="10" v-for="(item,index) in list[0].word_explain_list" :key="index">
                <a-col :span="2" style="text-align:right">
                  {{item.word_type}}
                </a-col>
                <a-col :span="22">
                  {{item.word_translate}}
                </a-col>
              </a-row>
            </div>
            <div class="box ztWrap">
              <div class="ztTitle">真题例句</div>
              <div class="ztList" v-if="hasWrodRole">
                <div class="ztItem" v-for="(item,index) in list[0].word_examples_list" :key="index">
                  <div class="ztName gmb-10">
                    <!-- <span class="nameType">{{item.subject_name}}</span> -->
                    <span class="name">试卷名称 {{item.title}}</span>
                  </div>
                  <div class="ztContent">
                    {{item.content}}
                  </div>
                </div>
              </div>
              <div class="gtc" v-else>
                <div class="mask gmv-10"></div>
                <a-button type="primary" @click="$router.push('/personal/vip')">开通会员</a-button>
              </div>
              <div class="btn">
                <a-button style="width:180px;" v-if="!list[0].remember" @click="list[0].remember=1">未斩</a-button>
                <a-button type="primary" style="width:180px;" v-else @click="list[0].remember=0">已斩</a-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </a-spin>

    <div class="stubottom">
      <a-button type="primary" icon="backward" style="background: #006daa;" @click="back">
        关闭
      </a-button>
      <div>
        <a-button type="primary" icon="forward" style="background: #006daa;" @click="next">
          下一题
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getModuleStudyList, stuAnswer } from "@/api/word";
import { getAudioUrl } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      param: {
        word_paper_id: "",
        module_id: "",
        type: "",
        page: 1,
        limit: 1,
      },
      list: [],
      total: 0,
      curPage: 1,
      paperName: "",
      showErr: false,
      loading: false,
    };
  },
  async created() {
    this.paperName = this.$route.query.name; //试卷名称
    this.param.word_paper_id = this.$route.query.id; //试卷id
    this.param.module_id = this.$route.query.module_id;
    this.param.type = Number(this.$route.query.type); //练习类型：1.抽查词库 2. 攻坚剩余 3. 复习掌握 4. 扫荡生词
    this.search();
  },
  computed: {
    ...mapGetters(["hasWrodRole"]),
  },
  methods: {
    play() {
      //播放
      var audios = document.getElementById("music");
      if (audios.paused) {
        audios.play();
      } else {
        audios.pause();
        audios.load();
        audios.play();
      }
    },
    async back() {
      //返回

      if (this.list[0].check) {
        await this.stuAnswer();
      }
      this.$router.back();
    },
    async next() {
      //下一题
      if (!this.list[0].check) {
        this.showErr = true;
        return;
      } else {
        this.showErr = false;
      }
      this.curPage++;
      if (this.curPage <= this.total) {
        let list = this.list[0];
        let { type } = this.param;
        //抽查词库page加一，攻坚剩余page都为1，复习掌握并且已斩page加一，扫荡生词并且为未斩page+1
        if (type == 1) {
          this.param.page++;
        } else if (type == 2) {
          this.param.page = 1;
        } else if (type == 3 && list.remember == 1) {
          this.param.page++;
        } else if (type == 4 && list.remember == 0) {
          this.param.page++;
        }

        this.stuAnswer(); //上传答案
      } else {
        await this.stuAnswer();
        this.$router.back();
      }
    },
    stuAnswer() {
      //上传答案
      let param = {
        is_right: this.list[0].remember == 1 ? 1 : 0,
        word: this.list[0].name,
        word_paper_id: this.param.word_paper_id,
        module_id: this.param.module_id,
      };

      return stuAnswer(param).then((data) => {
        if (data.code === 200) {
          this.search();
        }
      });
    },
    confirmOk() {
      //确认 检查单词是否错误
      let cur = this.list[0];
      if (!cur.answer) {
        this.$message.error("请填写答案");
        return;
      }
      if (cur.check) {
        return;
      }
      cur.check = cur.answer == cur.name ? 1 : 2;
      cur.remember = cur.check == 1 ? 1 : 0;
    },
    search() {
      //题目详情
      this.loading = true;
      getModuleStudyList(this.param).then((data) => {
        this.loading = false;
        if (data.code === 200) {
          this.list = data.data.map((item) => {
            return {
              ...item,
              check: false,
              answer: "",
              remember: 0,
              a_voice: getAudioUrl(item.a_voice || item.e_voice),
            };
          });
          this.$nextTick(() => {
            this.play();
          });
          if (!this.total) {
            this.total = data.total;
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mask {
  width: 100%;
  height: 200px;
  background: linear-gradient(#fff, #565050);
}
.study {
  .wrap {
    width: 800px;
    margin: auto;
  }
  .box {
    width: 400px;
    margin: auto;
  }
  height: 100vh;

  .stuHeader,
  .stubottom {
    width: 100%;
    background: #006daa;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  .stuHeader {
    height: 50px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .stubottom {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  .stuContent {
    overflow-y: auto;
    padding: 80px 0 72px 0;
    .subList {
      .subItem {
        .answerInput {
          text-align: center;
          .answerContent {
            display: flex;
            justify-content: center;
            .subInput {
              margin: 0 10px;
              border-bottom: 1px solid #999;
              input {
                text-align: center;
              }
              input:focus {
                outline: none !important;
                box-shadow: none;
              }
            }
          }
        }
        .check {
          font-size: 20px;
          text-align: center;
          margin: 20px 0;
          &.correct {
            color: #32e313;
          }
          &.wrong {
            color: #f00;
          }
          span {
            margin-left: 10px;
          }
        }
        .wordMean {
          border-bottom: 1px solid #999;
          padding-bottom: 20px;
          .meanTitle {
            font-size: 24px;
            color: #000;
            font-weight: 700;
          }
          .sortBox {
            display: flex;
            justify-content: space-between;
            .sortType {
              display: flex;
            }
          }
        }
        .ztWrap {
          position: relative;
          .ztTitle {
            font-size: 20px;
            text-align: center;
            margin-top: 20px;
            color: #000;
          }
          .ztList {
            overflow-y: auto;
            max-height: 400px;
            .ztItem {
              padding: 15px 0;
              .nameType {
                padding: 3px 5px;
                background: rgb(24, 144, 255);
                margin-right: 10px;
                color: #fff;
                border-radius: 3px;
              }
            }
          }
          .btn {
            text-align: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
</style>